let ErrorTypes = {
  API_GENERIC: 0,
  GNQ_GENERIC: 1,
  TOKEN_EXPIRED: 2,
  TOKEN_EXPIRED_AUTORESTART: 3,
  TOKEN_INVALID: 4,
  TOKEN_INVALID_AUTORESTART: 5,
  PROGRESS_RECOVERY_COMPLETED: 6,
  SITE_SCHEDULER_GENERIC: 7,
  SITE_SCHEDULER_ALREADY_BOOKED: 8,
  REVISION_CHANGED: 9
};

let ppdContactNumber = '<br>+1-(877)-359-7323'; // br because the number was being separated
let defaultPhoneNumbersToReplaceIfPPD = ['1-(855)-622-8748', '1-(855)-622-8748.']; // this will need changing if the number changes. right now this number is whats hardcoded into the translation strings

const languageToLocale = {
  //conversion map to moment recognised locales
  en: "en_US",
  de: "de_DE",
  pl: "pl_PL",
  hu: "hu_HU",
  uk: "uk_UA",
  cs: "cs_CZ",
  bg: "bg_BG",
  af: "af_ZA",
  zu: "zu_ZA"
};

function handleUserErrors(errorType) {
  removeUserError();  //should a previous error be presetn, remove it first
  let errorContent;
  let locale = m_screening.m_locale;

  if (!locale) {
    locale = $.urlParam('locale');
    if (!locale) {
      locale = $.urlParamFromString(sessionStorage["originalURL"], 'locale');
      if (!locale) {
        locale = languageToLocale[getNavigatorLanguage()] || languageToLocale['en'];   //if we get an error before m_screening (before first question), default language to navigator.language
      }
    }
  }

  setTheme(locale);

  // if no theme set then see if theres one in session storage
  if (!theme && sessionStorage.getItem('theme')) {
    theme = sessionStorage.getItem('theme');
  }

  //NOTE: defaultPhoneNumberToReplaceIfPPD right now matches the phone number hardcoded into the translation strings. a string.replace is done on this if ppd theme
  // if this number changes that variable will need updating to the new number

  switch (errorType) {
    case ErrorTypes.API_GENERIC:
    default:
      const apiGenericContent = errorContentStrings.api_generic[locale] || errorContentStrings.api_generic['en_US'];

      // switch number if ppd
      if (theme === 'ppd-eds' && apiGenericContent['contact']) {

        if (theme === 'ppd-eds' && ssGenericContent['contact']) {
          for (const num of defaultPhoneNumbersToReplaceIfPPD) {
            apiGenericContent['contact'] = apiGenericContent['contact'].replace(num, ppdContactNumber);
          }
        }
      }

      errorContent = `
        <img height="100px" src="./../images/illustrations/error_illustration.svg" class="mb-2 question-header-illustration" />
        <p>${apiGenericContent['header']}</p>
        <div class="smaller-black-text">
          <p>${apiGenericContent['subHeader']}</p>
          <p>${apiGenericContent['suggestedAction']}</p>
          <p><button type="button" class="submit centered ripple" onclick="restartSessionAndQuestionnaire()">${apiGenericContent['ctaText']}</button></p>
          <p>${apiGenericContent['contact']}</p>
        </div>`;
      break;
    case ErrorTypes.GNQ_GENERIC:
      const gnqGenericContent = errorContentStrings.gnq_generic[locale] || errorContentStrings.gnq_generic['en_US'];

      // switch number if ppd
      if (theme === 'ppd-eds' && gnqGenericContent['contact']) {
        for (const num of defaultPhoneNumbersToReplaceIfPPD) {
          gnqGenericContent['contactNumber'] = gnqGenericContent['contactNumber'].replace(num, ppdContactNumber);
        }
      }

      errorContent = `
        <img height="100px" src="./../images/illustrations/error_illustration.svg" class="mb-2 question-header-illustration" />
        <p>${gnqGenericContent['header']}</p>
        <div class="smaller-black-text">
          <p>${gnqGenericContent['subHeader']}</p>
          <p><button type="button" class="submit centered ripple" onclick="goToQuestion()">${gnqGenericContent['ctaText']}</button>
          <br>
          <br>
          <p>${gnqGenericContent['contact']}</p>
          <p><button type="button" class="secondary-action-btn centered ripple" onclick="restartSessionAndQuestionnaire()">${gnqGenericContent['cta2Text']}</button></p>
          <p>${gnqGenericContent['contactNumber']}</p>
        </div>`;
      break;
    case ErrorTypes.TOKEN_EXPIRED:
      const tokenExpiredContent = errorContentStrings.token_expired[locale] || errorContentStrings.token_expired['en_US'];
      errorContent = `
        <img height="100px" src="./../images/illustrations/error_illustration2.svg" class="mb-2 question-header-illustration" />
        <p>${tokenExpiredContent['header']}</p>
        <div class="smaller-black-text">
          <p>${tokenExpiredContent['subHeader']}</p>
          <p>${tokenExpiredContent['suggestedAction']}</p>
          <p><button type="button" class="submit centered ripple" onclick="restartSessionAndQuestionnaire()">${tokenExpiredContent['ctaText']}</button></p>
        </div>`;
      break;
    case ErrorTypes.TOKEN_INVALID:
      const tokenInvalidContent = errorContentStrings.token_invalid[locale] || errorContentStrings.token_invalid['en_US'];
      errorContent = `
      <img height="100px" src="./../images/illustrations/error_illustration.svg" class="mb-2 question-header-illustration" />
      <p>${tokenInvalidContent['header']}</p>
      <div class="smaller-black-text">
        <p>${tokenInvalidContent['subHeader']}</p>
        <p>${tokenInvalidContent['suggestedAction']}</p>
        <p><button type="button" class="submit centered ripple" onclick="restartSessionAndQuestionnaire()">${tokenInvalidContent['ctaText']}</button></p>
      </div>`;
      break;
    case ErrorTypes.TOKEN_EXPIRED_AUTORESTART:
    case ErrorTypes.TOKEN_INVALID_AUTORESTART:
      restartSessionAndQuestionnaire();
      return;
    case ErrorTypes.PROGRESS_RECOVERY_COMPLETED:
      const pRecoveryCompletedContent = errorContentStrings.progress_recovery_completed[locale] || errorContentStrings.progress_recovery_completed['en_US'];

      // switch number if ppd
      if (theme === 'ppd-eds' && pRecoveryCompletedContent['contact']) {
        for (const num of defaultPhoneNumbersToReplaceIfPPD) {
          pRecoveryCompletedContent['contact'] = pRecoveryCompletedContent['contact'].replace(num, ppdContactNumber);
        }
      }

      errorContent = `
        <img height="100px" src="./../images/illustrations/error_illustration2.svg" class="mb-2 question-header-illustration" />
        <p>${pRecoveryCompletedContent['header']}</p>
        <div class="smaller-black-text">
          <p>${pRecoveryCompletedContent['subHeader']}</p>
          <p><button type="button" class="submit centered ripple" onclick="restartSessionAndQuestionnaire()">${pRecoveryCompletedContent['ctaText']}</button></p>
          <p>${pRecoveryCompletedContent['contact']}</p>
        </div>`;
      break;
    case ErrorTypes.SITE_SCHEDULER_GENERIC:
      const ssGenericContent = errorContentStrings.site_scheduler_generic[locale] || errorContentStrings.site_scheduler_generic['en_US'];

      // switch number if ppd
      if (theme === 'ppd-eds' && ssGenericContent['contact']) {
        for (const num of defaultPhoneNumbersToReplaceIfPPD) {
          ssGenericContent['contact'] = ssGenericContent['contact'].replace(num, ppdContactNumber);
        }
      }

      errorContent = `
        <img height="100px" src="./../images/illustrations/error_illustration.svg" class="mb-2 question-header-illustration" />
        <p>${ssGenericContent['header']}</p>
        <div class="smaller-black-text">
          <p>${ssGenericContent['subHeader']}</p>
          <p><button type="button" class="submit centered ripple" onclick="goToQuestion()">${ssGenericContent['goBackCtaText']}</button>
          <br>
          <br>
          <p>${ssGenericContent['suggestedAction']}</p>
          <p><button type="button" class="secondary-action-btn centered ripple" onclick="restartSessionAndQuestionnaire()">${ssGenericContent['restartCtaText']}</button>
          </p>
          <p>${ssGenericContent['contact']}</p>
        </div>`;
      break;
    case ErrorTypes.REVISION_CHANGED: {
      const revisionChangedTexts = (languageStrings[locale] && languageStrings[locale].revision_changed) ? languageStrings[locale].revision_changed : languageStrings['en_US'].revision_changed;

      errorContent = `
          <img height="100px" src="./../images/illustrations/error_illustration2.svg" class="mb-2 question-header-illustration" />
          <p>${revisionChangedTexts.revision_changed_1}<br></p>
          <p>${revisionChangedTexts.revision_changed_2}</p>
          <div class="smaller-black-text">
            <p><button type="button" class="submit centered ripple" onclick="restartSessionAndQuestionnaire()">${revisionChangedTexts['ctaText']}</button></p>
          </div>`;
      break;
    }
    case ErrorTypes.MAINTENANCE: {
      // locale is got from the checkMaintenance api
      setTheme(curLocale);
      const maintenanceErrorContent = languageStrings[curLocale].maintenanceError || languageStrings['en_US'].maintenanceError;

			const maintenanceErrorExtraSurvey = maintenanceErrorContent['extra_survey'] ? maintenanceErrorContent['extra_survey']: '';
      // switch number if ppd
      errorContent = `
          <img height="100px" src="./../images/illustrations/error_illustration.svg" class="mb-2 question-header-illustration" />
          <p>${maintenanceErrorContent['header']}</p>
          <div class="smaller-black-text mb-5">
            <p>${maintenanceErrorContent['body']}</p>
            <p>${maintenanceErrorExtraSurvey}</p>
          </div>`;
      break;
    }
  }


  // hide the emtpy but bordered question container on ppd themeF
  if (sessionStorage.getItem('theme') === 'ppd-eds') {
    $('#question-container').hide();
  }

  $('#screener-container .loader').removeClass('show-loader');
  $('#questions-form').hide();
  $('#screener-container').append(`<div class="text-center mx-auto" id="fatalErrorContainer">${errorContent}</div>`);
  $('#fixed-bottom-bar').addClass('d-none');
  $('#fullscreenLoader').hide();
}

function removeUserError() {
  $('#fatalErrorContainer').remove();
  $('#questions-form, #questions').show();
  $('#fixed-bottom-bar').removeClass('d-none');
}